import { HOST, PORT, AUTH0_DOMAIN } from '../settings'
import { SubscriptionInterface } from './store'

const port = PORT ? `:${PORT}` : ''
export const ORIGIN = `${HOST}${port}`

export const getFullName = (user: any) => {
  const fullname = `https://${AUTH0_DOMAIN}:full_name`
  return user[fullname]
}

export const getSubscription = (user: any): SubscriptionInterface | undefined => {
  const subscription = `https://${AUTH0_DOMAIN}:subscription`
  return user?.[subscription] || undefined
}
