import { CurrencyCode } from '@zookal/ui-shared'
import { useReducer, useCallback } from 'react'
import { createContainer } from 'react-tracked'

export const PLAN_INFO = 'plan_info'
export const USER_INFO = 'user_info'
export const PAYMENT_ID_CHECKER = 'payment_id_checker'

export type storeTypes = keyof StatePropsInterface

interface StatePropsInterface {
  [PLAN_INFO]: PlanInfoInterface
  [USER_INFO]: UserInfoInterface
  [PAYMENT_ID_CHECKER]: PaymentIdCheckerInterface
}

export interface PlanInterface {
  charge_model: string
  currency_code: CurrencyCode
  description: string
  enabled_in_hosted_pages: boolean
  id: string
  invoice_name: string
  name: string
  period: number
  period_unit: string
  price: number
  pricing_model: string
  trial_period: number
  trial_period_unit: string
}

export interface SubscriptionInterface {
  plan?: string
  planId?: string
  subscriptionId?: string
  status?: string
  price?: number
  period?: number
  termEnd?: number
  nextPayment?: number
  sourcePlatform?: 'android' | 'ios' | 'web'
  cancellationScheduled?: boolean
  inTrial?: boolean
  pastDue?: boolean
  hasUniquePaymentId?: boolean
  currencyCode?: CurrencyCode
}
export interface UserInfoInterface {
  name?: string
  email?: string
  picture?: string
  accessToken?: string
  subscription?: SubscriptionInterface
}

export interface PlanInfoInterface {
  plans?: PlanInterface[]
}

export interface PaymentIdCheckerInterface {
  open: boolean
  inTrial?: boolean | null
  hasUniquePaymentId?: boolean
  plan?: string
  price?: string
  period?: string
}

export type storePayload = PlanInfoInterface | UserInfoInterface

type Action = {
  type: storeTypes
  payload: storePayload
}

const reducer = (state: StatePropsInterface, action: Action): StatePropsInterface => {
  const { type, payload } = action
  return {
    ...state,
    [type]: {
      ...state[type],
      ...payload,
    },
  }
}

export const planInfoInitialState: PlanInfoInterface = {
  plans: undefined,
}

export const userInfoInitialState: UserInfoInterface = {
  name: undefined,
  email: undefined,
  picture: undefined,
  accessToken: undefined,
  subscription: {
    plan: undefined,
    planId: undefined,
    subscriptionId: undefined,
    status: undefined,
    price: undefined,
    period: undefined,
    termEnd: undefined,
    nextPayment: undefined,
  },
}

export const paymentIdCheckerInitialState: PaymentIdCheckerInterface = {
  open: false,
  inTrial: null,
}

export const initialState: StatePropsInterface = {
  [PLAN_INFO]: planInfoInitialState,
  [USER_INFO]: userInfoInitialState,
  [PAYMENT_ID_CHECKER]: paymentIdCheckerInitialState,
}

const useValue = () => useReducer(reducer, initialState)
export const { Provider, useTracked, useUpdate, useTrackedState } = createContainer(useValue)

export const useDispatchByName = (name: storeTypes) => {
  const dispatch = useUpdate()
  const update = useCallback(
    (newVal: object) => {
      dispatch({
        type: name,
        payload: newVal,
      })
    },
    [dispatch, name],
  )
  return update
}
