import React, { ComponentType, memo } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { USER_INFO, useTrackedState } from '../lib/store'

interface PrivateRouteInterface extends RouteProps {
  component: ComponentType<RouteProps>
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteInterface) => {
  const userInfo = useTrackedState()[USER_INFO]

  return (
    <Route
      {...rest}
      render={(props: RouteProps) =>
        userInfo && userInfo?.subscription && userInfo?.subscription?.planId ? (
          <Component {...props} />
        ) : (
          <Redirect to='/' />
        )
      }
    />
  )
}

export default memo(PrivateRoute)
