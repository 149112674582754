import React, { memo } from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography } from '@material-ui/core'

type LoadingStyles = {
  fullPage: boolean
  error: string | undefined
}
const useStyles = makeStyles(() => ({
  root: ({ fullPage, error }: LoadingStyles) => ({
    display: 'flex',
    height: fullPage && !error ? '80vh' : '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: 100,
    minWidth: 100,
  }),
}))

type LoadingInterface = {
  text?: string | React.ReactElement | boolean
  fullPage?: boolean
  error?: string
  size?: number
  rest?: object
}
const Loading = (props?: LoadingInterface): React.ReactElement => {
  const text = props?.text
  const fullPage = props?.fullPage || true
  const error = props?.error
  const size = props?.size || 96
  const classes = useStyles({ fullPage, error })
  return (
    <Container maxWidth='sm' className={classes.root}>
      {text && <Typography gutterBottom>{text}</Typography>}
      {error ? (
        <Typography gutterBottom>Error: {error}</Typography>
      ) : (
        <CircularProgress color='primary' size={size} thickness={1} />
      )}
    </Container>
  )
}

export default memo(Loading)
