declare global {
  interface Window {
    dataLayer: any
  }
}

const exposeDataToGTM = (props: any) => {
  window.dataLayer.push(props)
}

export default exposeDataToGTM
