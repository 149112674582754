import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { Auth0Lock } from 'auth0-lock'

import './index.css'
import Providers from './Providers'
import Frame from './components/Frame'
import Routes from './pages/routes'
import { initializeTracking } from './lib/track-events'
import { GTM_ID, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './settings'
import { authConfig } from './auth0-config'
import { mParticleInit } from './lib/mparticle'

const tagManagerArgs = {
  gtmId: GTM_ID,
}
TagManager.initialize(tagManagerArgs)

export const auth0Lock = new Auth0Lock(AUTH0_CLIENT_ID, AUTH0_DOMAIN, authConfig)

initializeTracking()
mParticleInit()

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Frame>
        <Routes />
      </Frame>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
)
