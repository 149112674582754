import React, { ReactNode, memo } from 'react'
import { ApolloClient, ApolloProvider as DefaultApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { API_ENDPOINT } from '../settings'
import { setContext } from '@apollo/client/link/context'
import { USER_INFO, useTrackedState } from './store'

interface ThemeGraphQLProviderPropsInterface {
  children: ReactNode
}

const ApolloProvider = ({ children }: ThemeGraphQLProviderPropsInterface) => {
  const userInfo = useTrackedState()[USER_INFO]

  const httpLink = new HttpLink({
    uri: API_ENDPOINT,
  })

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: userInfo?.accessToken ? `Bearer ${userInfo?.accessToken}` : '',
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${{
            ...extensions,
          }}`,
        )
      })
    }
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })
  const apolloClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV === 'development',
  })

  return <DefaultApolloProvider client={apolloClient}>{children}</DefaultApolloProvider>
}

export default memo(ApolloProvider)
