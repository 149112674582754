import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import AppBarToolbarDesktop from './AppBarToolbarDesktop'
import AppBarToolbarMobile from './AppBarToolbarMobile'
import useCustomMediaQuery from './../lib/hooks/useCustomMediaQuery'
import { TBA_URL, TEST_PREP_URL, TEXTBOOKS_URL, ZOOKAL_WEB_URL } from '../lib/constants'

const useStyles = makeStyles((theme) => ({
  navHeading: {
    backgroundColor: '#fff',
  },
  logo: {
    cursor: 'pointer',
    '& img': {
      verticalAlign: 'middle',
    },
  },
  appBarTitle: { fontSize: 34, fontWeight: 600, marginLeft: 11 },
  toolbarRegular: {
    [theme.breakpoints.up('sm')]: {
      minHeight: 56,
    },
  },
  toolbar: {
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}))

const Links = [
  {
    url: TEXTBOOKS_URL,
    label: 'Textbooks',
  },
  {
    url: TEXTBOOKS_URL,
    label: 'Homework Help',
  },
  {
    url: TEST_PREP_URL,
    label: 'Test Prep',
  },
  {
    url: TBA_URL,
    label: 'Textbook Answers',
  },
]

const AppBar = () => {
  const classes = useStyles()
  const { isDesktop } = useCustomMediaQuery()

  return (
    <MuiAppBar position='static' className={classes.navHeading} elevation={0}>
      <Container disableGutters>
        <Toolbar className={classes.toolbar} classes={{ regular: classes.toolbarRegular }}>
          <Box
            onClick={() => {
              window.location.href = ZOOKAL_WEB_URL
            }}
            className={classes.logo}
            display='flex'
            minWidth={230}
            position='relative'
          >
            <img src={'/images/zookal-logo.svg'} alt='Subscriptions' />
            <Box position='absolute' right={0} bottom={-3}>
              <Typography display='inline' color='primary' className={classes.appBarTitle}>
                Study
              </Typography>
            </Box>
          </Box>
          {isDesktop ? <AppBarToolbarMobile links={Links} /> : <AppBarToolbarDesktop links={Links} />}
        </Toolbar>
      </Container>
    </MuiAppBar>
  )
}

export default memo(AppBar)
