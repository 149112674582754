import React, { FC, memo, ReactNode, ReactElement, useEffect, useState } from 'react'
import { ThemeProvider } from '@material-ui/core'
import theme from './lib/theme'
import { BrowserRouter } from 'react-router-dom'
import ApolloProvider from './lib/ApolloProvider'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Provider as StoreProvider } from './lib/store'
import Loading from './components/Loading'
import { HelmetProvider } from 'react-helmet-async'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { LAUNCH_DARKLY_CLIENT_ID } from './settings'

interface ThemeProviderProps {
  children: ReactNode
}

const Providers: FC<ThemeProviderProps> = ({ children }: ThemeProviderProps): ReactElement => {
  const [origin, setOrigin] = useState<string>()

  useEffect(() => {
    setOrigin(window.location.origin)
  }, [])

  if (!origin) return <Loading />
  return (
    <ThemeProvider theme={theme}>
      <StoreProvider>
        <ApolloProvider>
          <BrowserRouter>
            <HelmetProvider>{children}</HelmetProvider>
          </BrowserRouter>
        </ApolloProvider>
      </StoreProvider>
      <CssBaseline />
    </ThemeProvider>
  )
}

export default withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT_ID,
})(memo(Providers))
