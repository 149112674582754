import React, { ReactElement, memo, useEffect, Suspense, useState, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

import AppBar from './AppBar'
import Footer from './Footer'
import Loading from './Loading'
import { USER_INFO, useDispatchByName, userInfoInitialState } from '../lib/store'
import { getFullName, getSubscription } from '../lib/auth'
import Box from '@material-ui/core/Box'
import { auth0Lock } from '..'
import { setGACustomDimension, trackEventFB, trackEventGA } from '../lib/track-events'
import exposeDataToGTM from '../lib/gtm-datalayer'
import { getAuth0Session, getAuth0User } from './../lib/hooks/useAuth0'
import { Steps } from '../pages/subscriptions/hooks/useSteps'
import { useGetAndSaveQueryParams } from '../lib/hooks/useGetAndSaveQueryParams'
import { mParticleIdentifyUser } from '../lib/mparticle'

type FrameInterface = {
  children: ReactElement
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: '100vh',
  },
})

const Frame = ({ children }: FrameInterface): ReactElement => {
  const classes = useStyles()
  const [mounted, setMounted] = useState(false)
  const setUserInfo = useDispatchByName(USER_INFO)
  const history = useHistory()
  const { pathname } = useLocation()
  const isNewCheckoutFlowUrl = useMemo(() => pathname === '/' || pathname === '/premium', [pathname])
  useGetAndSaveQueryParams()

  useEffect(() => {
    const getUser = async (accessToken: string) => {
      const user = await getAuth0User(accessToken)

      if (user) {
        setGACustomDimension(user.sub)
        mParticleIdentifyUser(user.sub, user.email)
        setUserInfo({
          name: getFullName(user),
          email: user.email,
          picture: user.picture,
          subscription: getSubscription(user),
          accessToken: accessToken,
        })

        if (user.email && !getSubscription(user) && !window.location.search?.includes('step=checkout')) {
          history.push(`?step=${Steps.plans}`)
        }
        setMounted(true)
      } else {
        history.push(`?step=${Steps.auth}`)
        setUserInfo(userInfoInitialState)
        setMounted(true)
      }
    }

    const getSession = async () => {
      try {
        const sessionData = await getAuth0Session()
        await getUser(sessionData?.accessToken)
      } catch (error) {
        setUserInfo(userInfoInitialState)
        setMounted(true)
      }
    }

    getSession()

    auth0Lock.on('signup success', () => {
      const source = localStorage.getItem('source') || 'subscriptions_web'

      trackEventGA({
        category: 'Lead',
        action: 'Sign Up',
        label: source,
      })
      trackEventFB('Lead')
      exposeDataToGTM({
        event: 'Lead',
      })
    })

    auth0Lock.on('authenticated', async (sessionData) => {
      await getUser(sessionData.accessToken)
    })
  }, [history, pathname, setUserInfo])

  if (!mounted) {
    return <Loading />
  }

  return (
    <Suspense fallback={<Loading />}>
      <div className={classes.root}>
        <>
          {!isNewCheckoutFlowUrl && <AppBar />}
          <Box flex={1}>{children}</Box>
          {!isNewCheckoutFlowUrl && <Footer />}
        </>
      </div>
    </Suspense>
  )
}

export default memo(Frame)
