import { ENVIRONMENT } from './../../settings'

export const IS_PRODUCTION = ENVIRONMENT === 'production'
export const ZOOKAL_WEB_URL = IS_PRODUCTION ? 'https://www.zookal.com' : 'https://zookal-web.staging.aws.zookal.com'
export const TEXTBOOKS_URL = IS_PRODUCTION
  ? 'http://textbooks.zookal.com.au'
  : 'https://textbooks-staging.zookal.com.au'
export const HOMEWORK_HELP_URL = IS_PRODUCTION
  ? 'https://homework.zookal.com'
  : 'https://homework.staging.aws.zookal.com'
export const TEST_PREP_URL = 'https://testprep.zookal.com'
export const TBA_URL = IS_PRODUCTION ? 'http://tba.zookal.com' : 'http://tba.staging.aws.zookal.com'
