import { useLocation } from 'react-router-dom'

const useGetQueryParam = (param: string): string => {
  const { search } = useLocation()
  const queryParam = new URLSearchParams(search).get(param)

  return queryParam ?? ''
}

export default useGetQueryParam
