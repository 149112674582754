export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || ''
// App
export const HOST = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_HOST || ''
export const PORT = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_PORT || ''

// Auth0 config
export const AUTH0_DOMAIN = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_AUTH0_DOMAIN || ''
export const AUTH0_CLIENT_ID = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_AUTH0_CLIENT_ID || ''
export const AUTH0_API_IDENTIFIER = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_AUTH0_API_IDENTIFIER || ''

// API host
export const API_ENDPOINT = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_API_ENDPOINT || ''

// Chargebee
export const CHARGEBEE_SITE_NAME = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_CHARGEBEE_SITE_NAME
export const PUBLISHABLE_KEY = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_CHARGEBEE_PUBLISHABLE_KEY

// Paypal
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_PAYPAL_CLIENT_ID

// Tracking
export const GA_TRACKING_ID = process.env.REACT_APP_ZOOKAL_HOMEWORK_WEB_GA_TRACKING_ID || ''
export const FB_TRACKING_ID = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_FB_TRACKING_ID || ''
export const HOTJAR_ID = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_HOTJAR_ID || ''
export const GTM_ID = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_GTM_ID || ''

// LaunchDarkly
export const LAUNCH_DARKLY_CLIENT_ID = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_LAUNCH_DARKLY_CLIENT_ID || ''

// mParticle
export const MPARTICLE_API_KEY = process.env.REACT_APP_ZOOKAL_SUBSCRIPTIONS_WEB_MPARTICLE_API_KEY || ''
