import useMediaQuery from '@material-ui/core/useMediaQuery'

export interface UseCustomMediaQueryInterface {
  isDesktop: boolean
  isTablet: boolean
  isMobile: boolean
  isSmallMobile: boolean
}

const useCustomMediaQuery = (): UseCustomMediaQueryInterface => {
  const isDesktop = useMediaQuery('(max-width: 1200px)')
  const isTablet = useMediaQuery('(max-width: 959px)')
  const isMobile = useMediaQuery('(max-width: 600px)')
  const isSmallMobile = useMediaQuery('(max-width: 350px)')

  return {
    isDesktop,
    isTablet,
    isMobile,
    isSmallMobile,
  }
}

export default useCustomMediaQuery
