import React, { ComponentType, memo } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { USER_INFO, useTrackedState } from '../lib/store'

interface PublicRouteInterface extends RouteProps {
  component: ComponentType<RouteProps>
}

const PublicRoute = ({ component: Component, ...rest }: PublicRouteInterface) => {
  const userInfo = useTrackedState()[USER_INFO]

  return (
    <Route
      {...rest}
      render={(props: RouteProps) =>
        !userInfo || !userInfo?.subscription?.planId ? <Component {...props} /> : <Redirect to='/manage' />
      }
    />
  )
}

export default memo(PublicRoute)
