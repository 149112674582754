import amplitudeKit from '@mparticle/web-amplitude-kit'
import mParticle, { IdentityResult, UserIdentities } from '@mparticle/web-sdk'
import { ENVIRONMENT, MPARTICLE_API_KEY } from '../settings'

declare global {
  interface Window {
    mParticle: any
  }
}

let isUserIdentified = false
let isMParticleLoggedIn = false

export const mParticleInit = async () => {
  if (!MPARTICLE_API_KEY) return

  const mParticleConfig = {
    isDevelopmentMode: ENVIRONMENT !== 'production',
    logLevel: ENVIRONMENT !== 'production' ? 'verbose' : 'none',
    identityCallback: (result: any) => {
      isMParticleLoggedIn = result.body.is_logged_in
    },
  }

  const { default: appboyKit } = await import('@mparticle/web-appboy-kit')

  amplitudeKit.register(mParticleConfig)
  appboyKit.register(mParticleConfig)

  mParticle.init(MPARTICLE_API_KEY, mParticleConfig as any)
}

export const isMParticleReady = () => {
  return typeof window !== 'undefined' && window.mParticle && !!MPARTICLE_API_KEY
}

export const mParticleIdentifyUser = (auth0Id: string, email: string): void => {
  if (!isMParticleReady() || isUserIdentified) return

  mParticle.Identity.identify({ userIdentities: { customerid: auth0Id, email } }, (result: IdentityResult) => {
    isUserIdentified = true

    mParticleAliasExistingGuestUser(mParticle.Identity.getCurrentUser())

    const previousUser = result?.getPreviousUser()
    if (!previousUser) return

    const previousIdentities = previousUser.getUserIdentities() as { userIdentities: UserIdentities }
    const previousMPID = previousUser.getMPID()

    if (!isMParticleLoggedIn && !previousIdentities.userIdentities.customerid) {
      mParticle.Identity.aliasUsers({
        destinationMpid: mParticle.Identity.getCurrentUser().getMPID(),
        sourceMpid: previousMPID,
        startTime: previousUser.getFirstSeenTime(),
        endTime: Date.now(),
      })
    }
  })
}

export const mParticleSetUserAttribute = (attributeObject: Record<string, unknown>): boolean => {
  if (!isMParticleReady() || !isUserIdentified) return false
  const currentUser = mParticle.Identity.getCurrentUser()
  currentUser.setUserAttributes(attributeObject)
  return true
}

export const mParticlePageView = (eventName: string) => {
  if (!isMParticleReady()) return
  mParticle.logPageView(eventName, {
    title: document.title,
    url: window.location.toString(),
    domain: window.location.hostname,
    referrer: document.referrer,
  })
}

export const mParticleLogEvent = (eventName: string, eventType: mParticle.EventType, eventObject?: any) => {
  if (!isMParticleReady()) return
  mParticle.logEvent(eventName, eventType, eventObject)
}

export const mParticleLogout = (callback: () => void): void => {
  if (!isMParticleReady()) return

  const { userIdentities } = mParticle.Identity.getCurrentUser().getUserIdentities() as {
    userIdentities: UserIdentities
  }

  mParticle.Identity.logout({ userIdentities }, () => {
    isUserIdentified = false
    callback()
  })
}

export const mParticleAliasExistingGuestUser = (currentUser: mParticle.User): void => {
  const cookieName = 'guest_mparticle_user'

  if (!document.cookie.includes(`${cookieName}=`)) {
    return
  }

  const cookieParts = `; ${document.cookie}`.split(`; ${cookieName}=`)

  let cookieValue: { mpid?: string; seen?: number } = {}

  try {
    cookieValue = JSON.parse(cookieParts.pop()?.split(';').shift() || '{}')
  } catch (e) {}
  if (cookieValue.mpid === undefined || cookieValue.seen === undefined) {
    return
  }

  mParticle.Identity.aliasUsers({
    destinationMpid: currentUser.getMPID(),
    sourceMpid: cookieValue.mpid,
    startTime: cookieValue.seen,
    endTime: Date.now(),
    scope: 'mpid',
  } as any)

  document.cookie = `${cookieName}=; path=/; domain=.zookal.com; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
}
