import React, { lazy, memo, useEffect } from 'react'
import { Switch, Redirect, useLocation } from 'react-router-dom'

import PrivateRoute from '../components/PrivateRoute'
import PublicRoute from '../components/PublicRoute'
import { trackPageView } from '../lib/track-events'
const SubscriptionsNewFlow = lazy(() => import('./subscriptions'))
const Manage = lazy(() => import('./manage/Manage'))
const Study = lazy(() => import('./study/index'))

const Pages: React.FC = () => {
  const location = useLocation()

  useEffect(() => {
    trackPageView(location.pathname)
  }, [location.pathname])

  return (
    <Switch>
      <PublicRoute exact path='/' component={SubscriptionsNewFlow} />
      <PublicRoute exact path='/study' component={Study} />
      <PublicRoute exact path='/premium' component={SubscriptionsNewFlow} />
      <PrivateRoute exact path='/manage' component={Manage} />
      <Redirect to='/' />
    </Switch>
  )
}

export default memo(Pages)
