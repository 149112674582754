import { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import useGetQueryParam from '../../../lib/hooks/useGetQueryParam'

export enum Steps {
  auth = 'auth',
  plans = 'plans',
  checkout = 'checkout',
}

export interface UseStepsInterface {
  step: Steps | undefined
  goBackStep: () => void
  goNextStep: () => void
}

export const useSteps = (): UseStepsInterface => {
  const history = useHistory()
  const queryStep = useGetQueryParam('step')
  const [step, setStep] = useState<Steps>()

  const goToStep = useCallback(
    (step: Steps) => {
      setStep(step)
      history.replace(`?step=${step}`)
    },
    [history, setStep],
  )

  const goToAuthStep = useCallback(() => {
    goToStep(Steps.auth)
  }, [goToStep])

  const goToPlansStep = useCallback(() => {
    goToStep(Steps.plans)
  }, [goToStep])

  const goToCheckoutStep = useCallback(() => {
    goToStep(Steps.checkout)
  }, [goToStep])

  const goBackStep = useCallback(() => {
    switch (step) {
      case Steps.plans:
        goToAuthStep()
        break
      case Steps.checkout:
        goToPlansStep()
        break
    }
  }, [step, goToAuthStep, goToPlansStep])

  const goNextStep = useCallback(() => {
    switch (step) {
      case Steps.auth:
        goToPlansStep()
        break
      case Steps.plans:
        goToCheckoutStep()
        break
    }
  }, [step, goToPlansStep, goToCheckoutStep])

  useEffect(() => {
    if (queryStep && queryStep in Steps) {
      goToStep(queryStep as Steps)
    } else if (!step) {
      goToStep(Steps.auth)
    }
  }, [queryStep, step, goToStep])

  return { step, goBackStep, goNextStep }
}
