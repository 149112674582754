import { auth0Lock } from '../..'
import { mParticleLogout } from '../mparticle'

export const getAuth0Session = async (): Promise<any> => {
  return new Promise((resolve, reject) => {
    auth0Lock.checkSession({}, (error, authResult) => {
      if (error) reject(error)
      if (authResult && authResult.accessToken) {
        resolve(authResult)
      }
    })
  })
}

export const getAuth0User = async (accessToken: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    auth0Lock.getUserInfo(accessToken, (error, user) => {
      if (error) reject(error)
      if (user) {
        resolve(user)
      }
    })
  })
}

export const logout = () => {
  mParticleLogout(() => {
    auth0Lock.logout({ returnTo: `${window.location.origin}?step=auth` })
  })
}
