import ReactGA, { EventArgs } from 'react-ga'
import ReactFbPixel from 'react-facebook-pixel'
import { GA_TRACKING_ID, FB_TRACKING_ID } from './../settings'
import hotjar from '../lib/hotjar'

export const initializeTracking = () => {
  ReactGA.initialize(GA_TRACKING_ID, { gaOptions: { cookieDomain: 'auto' } })
  ReactFbPixel.init(FB_TRACKING_ID)
  hotjar.initialize()
}

export const setGACustomDimension = (id: string) => {
  ReactGA.set({ userId: id })
  ReactGA.set({ dimension1: id })
}

export const trackPageView = (route: string) => {
  ReactGA.pageview(route)
  ReactFbPixel.pageView()
}

export const trackEventGA = ({ category, action, label, value }: EventArgs) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: !!value ? +(value * 100).toFixed(0) : value,
  })
}

export const trackEventFB = (eventName: string, params?: any) => {
  ReactFbPixel.track(eventName, params)
}

export const trackEventFBCustom = (eventName: string, params?: any) => {
  ReactFbPixel.trackCustom(eventName, params)
}
