import React, { useState, useCallback, memo } from 'react'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import MenuOutlined from '@material-ui/icons/MenuOutlined'
import ExitToApp from '@material-ui/icons/ExitToApp'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import { USER_INFO, useTrackedState } from '../lib/store'
import { logout } from '../lib/hooks/useAuth0'

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    color: '#555',
  },
  fullName: {
    color: '#555',
    margin: theme.spacing(0, 1),
    overflow: 'hidden',
    maxHeight: theme.spacing(8),
    '&:first-child': {
      maxWidth: theme.spacing(20),
    },
  },
  linkMobile: {
    padding: theme.spacing(1),
    width: '100%',
    fontWeight: 'normal',
  },
  logInButtonMobile: {
    marginBottom: theme.spacing(2),
  },
  list: {
    width: 250,
  },
  exitAppIcon: {
    marginRight: theme.spacing(2),
  },
}))

interface AppBarToolbarMobileInterface {
  links: any[]
}

const AppBarToolbarMobile = ({ links }: AppBarToolbarMobileInterface) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const userInfo = useTrackedState()[USER_INFO]
  const history = useHistory()

  const toggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [setIsOpen, isOpen])

  return (
    <Box display='flex' flex='1' justifyContent='flex-end'>
      <IconButton onClick={toggle}>
        <MenuOutlined className={classes.menuIcon} />
      </IconButton>

      <Drawer anchor={'right'} open={isOpen} onClose={toggle}>
        {userInfo && userInfo?.name && (
          <Box display='flex' alignItems='center' p={2}>
            <Avatar src={userInfo?.picture} alt={userInfo?.name} />
            <Typography variant='body1' noWrap className={classes.fullName}>
              {userInfo?.name}
            </Typography>
          </Box>
        )}
        <Divider />
        <List className={classes.list}>
          {links.map((link, index: number) => (
            <ListItem button key={index.toString()}>
              <Link href={link.url} className={classes.linkMobile} color='textPrimary' underline='none'>
                {link.label}
              </Link>
            </ListItem>
          ))}
        </List>
        <Divider />
        {userInfo && userInfo?.name ? (
          <Box p={2}>
            <Button
              onClick={() => {
                sessionStorage.setItem('initialScreen', 'login')
                logout()
              }}
              fullWidth
            >
              <ExitToApp className={classes.exitAppIcon} /> Sign out
            </Button>
          </Box>
        ) : (
          <Box display='flex' flexDirection='column' p={2}>
            <Button
              onClick={() => {
                sessionStorage.setItem('initialScreen', 'login')
                history.push(`/?step=auth`)
              }}
              className={classes.logInButtonMobile}
            >
              Log In
            </Button>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                history.push(`/?step=auth`)
              }}
            >
              Sign up
            </Button>
          </Box>
        )}
      </Drawer>
    </Box>
  )
}

export default memo(AppBarToolbarMobile)
