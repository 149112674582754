import { HOMEWORK_HELP_URL } from './lib/constants/index'
import { AUTH0_API_IDENTIFIER } from './settings'

export const authConfig = {
  container: 'auth0-form',
  initialScreen: 'signUp' as any,
  allowShowPassword: true,
  auth: {
    sso: true,
    audience: AUTH0_API_IDENTIFIER,
    redirect: false,
  },
  theme: {
    logo: '',
    primaryColor: '#FF9A37',
  },
  additionalSignUpFields: [
    {
      type: 'text' as any,
      name: 'full_name',
      icon: 'https://s3-ap-southeast-2.amazonaws.com/assets.zookal.com/images/misc/icon-fullname2.svg',
      placeholder: 'Full name',
      validator: function (name: string) {
        return {
          valid: name.trim().length >= 1,
          hint: 'Must have at least 1 character',
        }
      },
    },
    {
      type: 'checkbox' as any,
      name: 'is_subscribed_marketing',
      prefill: 'true',
      placeholder: 'Yes please, I’d like to receive future communications from Zookal.',
    },
  ],
  languageDictionary: {
    title: '',
    forgotPasswordTitle: '',
    forgotPasswordAction: 'Forgot your password?',
    passwordInputPlaceholder: 'password',
    signUpTitle: '',
    loginSubmitLabel: 'Login',
    loginLabel: 'LOGIN',
    signUpSubmitLabel: 'Create Account',
    signUpLabel: 'CREATE ACCOUNT',
    signUpTerms: `By signing up, you agree to our <a href="${HOMEWORK_HELP_URL}/terms-of-use" target="_new">terms of services</a> and <a href="${HOMEWORK_HELP_URL}/privacy-policy" target="_new">privacy policy.</a>`,
    signUpWithLabel: 'Create account with %s',
    loginWithLabel: 'Log in with %s',
    error: {
      signUp: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invalid_signup: 'This email address may be associated with an existing account. Please log in to continue.',
      },
    },
  },
}
