import { createMuiTheme } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/styles'

import ProximaNovaThin from './../static/fonts/ProximaNova-Thin.ttf'
import ProximaNovaLight from './../static/fonts/ProximaNova-Light.ttf'
import ProximaNovaRegular from './../static/fonts/ProximaNova-Regular.ttf'
import ProximaNovaSemibold from './../static/fonts/ProximaNova-Semibold.ttf'
import ProximaNovaBold from './../static/fonts/ProximaNova-Bold.ttf'

const proximaNovaFonts = [
  {
    name: 'Thin',
    weight: 200,
    font: ProximaNovaThin,
  },
  {
    name: 'Light',
    weight: 300,
    font: ProximaNovaLight,
  },
  {
    name: 'Regular',
    weight: 400,
    font: ProximaNovaRegular,
  },
  {
    name: 'Semibold',
    weight: 600,
    font: ProximaNovaSemibold,
  },
  {
    name: 'Bold',
    weight: 700,
    font: ProximaNovaBold,
  },
]

const proximaNova: CSSProperties[] = proximaNovaFonts.map((font) => ({
  fontFamily: '"Proxima Nova"',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: font.weight,
  src: `url(${font.font}) format('truetype')`,
}))

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Proxima Nova", Roboto, "Helvetica Neue", Arial, sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: '56px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
      lineHeight: '48px',
      letterSpacing: '0.5px',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 400,
      lineHeight: '40px',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '32px',
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.18px',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '16px',
      letterSpacing: '1.25px',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
    overline: {
      fontSize: '0.625rem',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': proximaNova,
      },
    },
    MuiAccordionSummary: {
      content: {
        '&.Mui-expanded': {
          margin: '10px 0',
        },
      },
      root: {
        '&.Mui-expanded': {
          minHeight: 71,
        },
      },
    },
    MuiButton: {
      root: {
        height: '60px',
        '&.Mui-disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'auto'
        }
      },
      contained: {
        boxShadow: 'none',
        backgroundColor: '#828894',
        color: 'rgba(255, 255, 255, 0.87)',
      },
      outlinedPrimary: {
        borderColor: 'rgba(255, 154, 55)',
        borderWidth: 2,
        '&:hover': {
          borderColor: 'rgba(255, 154, 55)',
          borderWidth: 2,
        },
      },
    },
    MuiIconButton: {
      root: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'auto'
          }
        }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      outlined: {
        border: '1px solid transparent',
      },
    },
  },
  palette: {
    primary: {
      main: '#FF9A37',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#538DFF',
      contrastText: '#FFF',
    },
    error: {
      main: '#FF0000',
    },
    success: {
      main: '#44C4A1',
    },
    text: {
      primary: '#444444',
      disabled: '#A09E9E',
    },
    background: {
      default: '#FFF',
      paper: '#FFF',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, // phone
      md: 960, // ipad
      lg: 1440, // laptop
      xl: 1920,
    },
  },
})

export default { ...theme,  
  custom: {
    palette: {
      purple: {
        main: '#5956D8',
        light: '#C5C3FD',
        dark: '#44429F',
      },
    },
  }, 
}

