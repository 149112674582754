import React, { useState, useRef, useCallback, memo } from 'react'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExitToApp from '@material-ui/icons/ExitToApp'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import clsx from 'clsx'
import { USER_INFO, useTrackedState } from '../lib/store'
import { logout } from '../lib/hooks/useAuth0'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    borderRadius: 4,
    marginRight: 'auto',
  },
  fullName: {
    color: '#555',
    margin: theme.spacing(0, 1),
    overflow: 'hidden',
    maxHeight: theme.spacing(8),
    '&:first-child': {
      maxWidth: theme.spacing(20),
    },
  },
  links: {
    flexGrow: 1,
    marginLeft: theme.spacing(3),
  },
  link: {
    padding: theme.spacing(3),
    fontWeight: 'normal',
  },
  expandMore: {
    color: '#555',
  },
  logInButton: {
    marginRight: theme.spacing(2),
    fontWeight: 700,
  },
  signupBtn: {
    height: theme.spacing(5),
    fontWeight: 700,
  },
  exitAppIcon: {
    marginRight: theme.spacing(2),
  },
}))

interface AppBarToolbarDesktopInterface {
  links: any[]
}

const AppBarToolbarDesktop = ({ links }: AppBarToolbarDesktopInterface) => {
  const classes = useStyles()
  const anchorRef = useRef(null as any)
  const [open, setOpen] = useState(false)
  const userInfo = useTrackedState()[USER_INFO]
  const history = useHistory()

  const handleClose = useCallback(
    (event: any) => {
      if (anchorRef.current && anchorRef.current?.contains(event.target)) {
        return
      }
      setOpen(false)
    },
    [setOpen],
  )

  const handleListKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Tab') {
        event.preventDefault()
        setOpen(false)
      }
    },
    [setOpen],
  )

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [setOpen])

  return (
    <>
      <Box className={classes.links}>
        {links.map((link, index: number) => (
          <Link key={index.toString()} href={link.url} className={classes.link} color='textPrimary' underline='none'>
            {link.label}
          </Link>
        ))}
      </Box>
      {userInfo && userInfo?.name ? (
        <IconButton
          ref={anchorRef}
          onClick={handleToggle}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          className={clsx('navbar-icon-avatar', classes.menuButton)}
          color='inherit'
        >
          <Avatar src={userInfo?.picture} alt={userInfo?.name} />
          <Typography variant='body1' noWrap className={classes.fullName}>
            {userInfo?.name}
          </Typography>
          <ExpandMore className={classes.expandMore} />
        </IconButton>
      ) : (
        <>
          <Button
            onClick={() => {
              sessionStorage.setItem('initialScreen', 'login')
              history.push(`/?step=auth`)
            }}
            className={classes.logInButton}
          >
            Log In
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              history.push(`/?step=auth`)
            }}
            className={classes.signupBtn}
          >
            Sign up
          </Button>
        </>
      )}
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                  <MenuItem
                    onClick={(event) => {
                      handleClose(event)
                      sessionStorage.setItem('initialScreen', 'login')
                      logout()
                    }}
                  >
                    <ExitToApp className={classes.exitAppIcon} />
                    <ListItemText className='profile-menu-signout' primary='Sign out' />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default memo(AppBarToolbarDesktop)
