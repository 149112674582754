import { useEffect } from 'react'
import useGetQueryParam from './useGetQueryParam'

export const useGetAndSaveQueryParams = () => {
  const callbackUrl = useGetQueryParam('callback_url')
  const notAutomaticallyRedirect = useGetQueryParam('not_automatically_redirect')
  const source = useGetQueryParam('source')
  const selectedPlanType = useGetQueryParam('selected_plan_type')
  const initialScreen = useGetQueryParam('initial_screen')
  const campaignUrl = useGetQueryParam('campaign_url')

  useEffect(() => {
    if (callbackUrl) {
      localStorage.setItem('redirectBackUrl', callbackUrl)
    }
    if (callbackUrl && !notAutomaticallyRedirect) {
      localStorage.setItem('callbackUrl', callbackUrl)
    }
    if (source) {
      localStorage.setItem('source', source)
    }
    if (selectedPlanType) {
      localStorage.setItem('selectedPlanType', selectedPlanType)
    }
    if (initialScreen) {
      sessionStorage.setItem('initialScreen', initialScreen)
    }
    if (campaignUrl) {
      localStorage.setItem('campaignUrl', campaignUrl)
    }
  }, [callbackUrl, notAutomaticallyRedirect, source, selectedPlanType, initialScreen, campaignUrl])
}
