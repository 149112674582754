import React from 'react'
import Typography from '@material-ui/core/Typography'

const Copyright = (props: any) => {
  return (
    <Typography variant='caption' color='inherit' {...props} component='p'>
      {'© 2011-'}
      {new Date().getFullYear()} Zookal Pty Ltd 82 147 685 452
    </Typography>
  )
}

export default Copyright
