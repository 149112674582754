import React, { useCallback } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Copyright from './Copyright'
import MuiLink from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { trackEventGA } from '../lib/track-events'
import { HOMEWORK_HELP_URL, TBA_URL, TEST_PREP_URL, TEXTBOOKS_URL } from '../lib/constants'

const useStyles = makeStyles((theme) => ({
  listRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'space-between'
  },
  siteLinks: {
    width: 300,
    [theme.breakpoints.down('xs')]: {
      width: 200,
    },
  },
  siteListItemRoot: {
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.75, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.5, 0),
    },
  },
  socialLinks: {
    // width: 150,
    [theme.breakpoints.down('xs')]: {
      width: 100,
      '& img': {
        width: 20,
      },
    },
  },
  socialListItemRoot: {
    width: 50,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  footerMarginTop: {
    marginTop: theme.spacing(20),
  },
  footer: {
    background: '#FFF',
    padding: theme.spacing(3, 2),
    borderTop: '1px solid #d4d4d4',
    '&>div': {
      marginBottom: 20,
      '&>img': {
        [theme.breakpoints.down('xs')]: {
          width: '135px',
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 0),
    },
  },
  copyright: {
    margin: '30px 0 20px',
  },
  paymentLogos: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0, 0),
    },
    '& div': {
      display: 'flex',
      justifyContent: 'center',
      border: '1px solid #E5E5E5',
      borderRadius: 5,
      padding: theme.spacing(0.6, 2.5),
      margin: theme.spacing(0, 1),
      height: 40,
      width: '100%',
      maxWidth: '80px',
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0, 2, 2, 0),
      },
    },
  },
}))

const socialLinks: any = {
  facebook: 'https://www.facebook.com/zookal',
  twitter: 'https://twitter.com/zookal',
  instagram: 'https://www.instagram.com/zookal',
  youtube: 'https://www.youtube.com/channel/UCjYyuj3BDxmfYNd7BlvCfvg',
  // snapchat: '#',
  wechat: 'https://mp.weixin.qq.com/s/-TskBnGRBnbDz1nRHCeCjw',
}

const links = [
  {
    label: 'Company',
    children: [
      {
        title: 'Our Story',
        link: `${TEXTBOOKS_URL}/pages/about-zookal`,
      },
      {
        title: 'Terms of use',
        link: `${HOMEWORK_HELP_URL}/terms-of-use`,
      },
      {
        title: 'Privacy policy',
        link: `${HOMEWORK_HELP_URL}/privacy-policy`,
      },
    ],
  },
  {
    label: 'Explore',
    children: [
      {
        title: 'Homework Help',
        link: HOMEWORK_HELP_URL,
      },
      {
        title: 'Test Prep App',
        link: TEST_PREP_URL,
      },
      {
        title: 'Textbooks',
        link: TEXTBOOKS_URL,
      },
      {
        title: 'eTextbooks',
        link: `${TEXTBOOKS_URL}/collections/etextbooks`,
      },
      {
        title: 'Textbook Answers',
        link: TBA_URL,
      },
    ],
  },
  {
    label: 'Customer Support',
    children: [
      {
        title: 'FAQ',
        link: `${HOMEWORK_HELP_URL}/faq`,
      },
    ],
  },
]
export default () => {
  const classes = useStyles()
  const theme = useTheme()
  const atXs = useMediaQuery(theme.breakpoints.only('xs'))

  const footerLinkClickEvent = useCallback((linkText: string) => {
    trackEventGA({
      category: 'Internal Link',
      action: 'click',
      label: linkText,
    })
  }, [])

  return (
    <footer className={classes.footer} id='footer'>
      <Container maxWidth='lg'>
        <img src='/images/zookal-logo-orange.svg' alt='Zookal' />
      </Container>
      <Container maxWidth='lg'>
        <Grid container spacing={1} justify='space-between'>
          <Grid item xs={12} md={6}>
            <Box display={atXs ? 'block' : 'flex'}>
              {links.map((parent, index) => {
                return (
                  <Box width='100%' maxWidth='170px' marginBottom={atXs ? 2 : 0} key={index}>
                    <Typography>
                      <strong>{parent.label}</strong>
                    </Typography>
                    <List classes={{ root: classes.listRoot }} className={classes.siteLinks}>
                      {parent.children.map((siteLink, sIndex) => {
                        const RenderItem = () => {
                          return (
                            <MuiLink
                              color='inherit'
                              underline='none'
                              href={siteLink.link}
                              target='_blank'
                              variant={atXs ? 'caption' : 'body1'}
                              onClick={() => footerLinkClickEvent(siteLink.title)}
                            >
                              {siteLink.title}
                            </MuiLink>
                          )
                        }
                        return (
                          <ListItem
                            dense
                            key={siteLink.title}
                            disableGutters
                            classes={{ root: classes.siteListItemRoot }}
                          >
                            <RenderItem />
                          </ListItem>
                        )
                      })}
                    </List>
                  </Box>
                )
              })}
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box>
              <Grid container justify={atXs ? 'flex-start' : 'flex-end'} className={classes.paymentLogos}>
                <Grid item xs={4} md={3}>
                  <img src='/images/visa-icon.svg' alt='' />
                </Grid>
                <Grid item xs={4} md={3}>
                  <img src='/images/mastercard-icon.svg' alt='' />
                </Grid>
                <Grid item xs={4} md={3}>
                  <img src='/images/paypal-icon.svg' alt='' />
                </Grid>
                <Grid item xs={4} md={3}>
                  <img src='/images/amex-icon.svg' alt='' />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box
          display='flex'
          justifyContent='space-between'
          maxWidth={atXs ? '200px' : '250px'}
          marginLeft={atXs ? 'initial' : 'auto'}
          marginTop={5}
        >
          {Object.keys(socialLinks).map((socialKey) => (
            <Box key={socialKey}>
              <a
                href={socialLinks[socialKey]}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => footerLinkClickEvent(socialKey)}
              >
                <img src={`/images/${socialKey}-icon.svg`} alt='' className={socialKey} />
              </a>
            </Box>
          ))}
        </Box>
        <Copyright className={classes.copyright} />
      </Container>
    </footer>
  )
}
